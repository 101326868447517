<template>
  <c-box w="full">
    <Breadcrumbs :items="breadcrumbItems" />
    <hr
      v-chakra
      w="full"
    >

    <c-text
      mt="30px"
      font-family="Montserrat"
      font-size="24px"
      font-weight="700"
      line-height="36px"
    >
      Pilih Ahli Gizi
    </c-text>

    <c-box mt="30px">
      <c-text font-size="18px">
        Ahli gizi tersedia
      </c-text>
      <hr>
      <c-flex
        mt="20px"
        flex-wrap="wrap"
        grid-gap="20px"
      >
        <c-flex
          v-for="(item, index) in nutritionists"
          :key="index"
          p="20px"
          border="1px solid #F2F2F2"
          box-shadow="0px 5px 30px 0px #0000000D"
          rounded="md"
          min-h="165px"
          w="435px"
        >
          <c-image
            :src="getProfilePhoto(item)"
            object-fit="cover"
            rounded="full"
            h="96px"
            w="96px"
            flex-shrink="0"
          />
          <c-box
            w="full"
            ml="20px"
          >
            <c-text
              font-size="18px"
              font-weight="700"
            >
              {{ item.firstName }} {{ item.lastName }} {{ item.title }}
            </c-text>
            <c-text
              font-size="16px"
              font-weight="400"
              color="primary.400"
            >
              {{ item.specialization | formatNull }}
            </c-text>
            <c-text
              v-for="edu in item.education"
              :key="edu.level"
              font-size="14px"
              font-weight="400"
              line-height="21px"
              color="grey"
            >
              {{ edu.level | formatNull }} {{ edu.university | formatNull }}
            </c-text>
            <c-flex
              w="full"
              mt="20px"
              align-items="center"
              justify-content="space-between"
            >
              <c-button
                bg="#F4CC46"
                color="#927102"
                rounded="full"
                @click="selectNutritionist(item.id)"
              >
                <c-text>Pilih</c-text>
                <c-image
                  :src="require('@/assets/icon-arrow-right-yellow.svg')"
                  h="24px"
                  w="24px"
                  ml="5px"
                />
              </c-button>
              <c-text
                ml="auto"
                mr="0"
                font-size="16px"
                color="gray.900"
              >
                <c-image
                  :src="require('@/assets/icon-star.svg')"
                  alt="icon"
                  display="inline-block"
                  vertical-align="middle"
                  margin-right="0px"
                />
                {{ item.rating }}/5
              </c-text>
            </c-flex>
          </c-box>
        </c-flex>
      </c-flex>
      <c-box
        mt="30px"
        ml="auto"
        w="fit-content"
      >
        <Pagination
          :current-page="queryPage"
          :max-visible-buttons="5"
          :per-page="perPage"
          :total-pages="totalPage"
          @pagechanged="$router.push({ query: { page: $event } })"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import Breadcrumbs from '@/components/breadcrumb.vue'
import Pagination from '@/components/pagination.vue'

export default {
  name: 'SUClientNutris',
  components: { Breadcrumbs, Pagination },
  data() {
    return {
      nutritionists: [],
      totalPage: 0,
      perPage: 0,
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    breadcrumbItems() {
      let clientId = this.clientId
      let programId = this.programId

      return [
        {
          label: 'Manajemen Klien',
          href: this.$router.resolve({ name: 'superadmin.clients' }).href,
        },
        {
          label: 'Detail Klien',
          href: this.$router.resolve({
            name: 'superadmin.clients-detail',
            params: { clientId },
          }).href,
        },
        {
          label: 'Detail Program',
          href: this.$router.resolve({
            name: 'superadmin.clients-programs',
            params: { clientId, programId },
          }).href,
        },
        { label: 'Pilih Ahli Gizi', href: null, isCurrent: true },
      ]
    },
    query() {
      return this.$route.query.q
    },
    queryPage() {
      return +(this.$route.query.page ?? 1)
    },
    combinedQuery() {
      return [this.query, this.queryPage]
    },
  },
  watch: {
    combinedQuery: {
      immediate: true,
      async handler([query, page]) {
        let data = await this.$store.dispatch(
          'suManagementClient/listNutritionist',
          { query, page },
        )

        this.nutritionists = data.data
        let totalData = data.meta.total
        this.perPage = data.meta.pagesize

        this.totalPage = Math.ceil(totalData / this.perPage)
      },
    },
  },
  methods: {
    education(item) {
      return item.education
    },
    async selectNutritionist(nutritionistId) {
      let programId = this.programId
      let prefix = this.isRoleSuperAdmin ? 'superadmin' : 'admin'
      await this.$store.dispatch(
        'suManagementClient/updateProgramNutritionist',
        { programId, nutritionistId },
      )
      await this.$router.replace({
        name: `${prefix}.clients-programs`,
        params: { clientId: this.clientId, programId: this.programId },
      })
    },
    getProfilePhoto(item) {
      return item.photoUrl ?? 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'
    },
  },
}
</script>
