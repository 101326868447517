var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full"
    }
  }, [_c('Breadcrumbs', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "full"
    }
  }), _c('c-text', {
    attrs: {
      "mt": "30px",
      "font-family": "Montserrat",
      "font-size": "24px",
      "font-weight": "700",
      "line-height": "36px"
    }
  }, [_vm._v(" Pilih Ahli Gizi ")]), _c('c-box', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px"
    }
  }, [_vm._v(" Ahli gizi tersedia ")]), _c('hr'), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "flex-wrap": "wrap",
      "grid-gap": "20px"
    }
  }, _vm._l(_vm.nutritionists, function (item, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "p": "20px",
        "border": "1px solid #F2F2F2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "rounded": "md",
        "min-h": "165px",
        "w": "435px"
      }
    }, [_c('c-image', {
      attrs: {
        "src": _vm.getProfilePhoto(item),
        "object-fit": "cover",
        "rounded": "full",
        "h": "96px",
        "w": "96px",
        "flex-shrink": "0"
      }
    }), _c('c-box', {
      attrs: {
        "w": "full",
        "ml": "20px"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "18px",
        "font-weight": "700"
      }
    }, [_vm._v(" " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + " " + _vm._s(item.title) + " ")]), _c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatNull")(item.specialization)) + " ")]), _vm._l(item.education, function (edu) {
      return _c('c-text', {
        key: edu.level,
        attrs: {
          "font-size": "14px",
          "font-weight": "400",
          "line-height": "21px",
          "color": "grey"
        }
      }, [_vm._v(" " + _vm._s(_vm._f("formatNull")(edu.level)) + " " + _vm._s(_vm._f("formatNull")(edu.university)) + " ")]);
    }), _c('c-flex', {
      attrs: {
        "w": "full",
        "mt": "20px",
        "align-items": "center",
        "justify-content": "space-between"
      }
    }, [_c('c-button', {
      attrs: {
        "bg": "#F4CC46",
        "color": "#927102",
        "rounded": "full"
      },
      on: {
        "click": function click($event) {
          return _vm.selectNutritionist(item.id);
        }
      }
    }, [_c('c-text', [_vm._v("Pilih")]), _c('c-image', {
      attrs: {
        "src": require('@/assets/icon-arrow-right-yellow.svg'),
        "h": "24px",
        "w": "24px",
        "ml": "5px"
      }
    })], 1), _c('c-text', {
      attrs: {
        "ml": "auto",
        "mr": "0",
        "font-size": "16px",
        "color": "gray.900"
      }
    }, [_c('c-image', {
      attrs: {
        "src": require('@/assets/icon-star.svg'),
        "alt": "icon",
        "display": "inline-block",
        "vertical-align": "middle",
        "margin-right": "0px"
      }
    }), _vm._v(" " + _vm._s(item.rating) + "/5 ")], 1)], 1)], 2)], 1);
  }), 1), _c('c-box', {
    attrs: {
      "mt": "30px",
      "ml": "auto",
      "w": "fit-content"
    }
  }, [_c('Pagination', {
    attrs: {
      "current-page": _vm.queryPage,
      "max-visible-buttons": 5,
      "per-page": _vm.perPage,
      "total-pages": _vm.totalPage
    },
    on: {
      "pagechanged": function pagechanged($event) {
        return _vm.$router.push({
          query: {
            page: $event
          }
        });
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }